.subscription-stripe{
    box-sizing: border-box;
    height: 45px;
    padding: 14px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #f2f2f2;
    box-shadow: 0 1px 3px 0 #000;
    transition: box-shadow 150ms ease;
    margin: 10px auto 20px;
}

.subscription-container {
    h6{
        font-weight: bold;
    }
    td{
        padding: 5px !important;
    }
    /*ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }*/
}

.text-login {
    color: red;
}

.rolo-form {
    label{
        >span {
            color:#145388
        }
    }
}

.btn-rolo{
    padding: 2px 5px;
    border-radius: 2px;
    font-size: 0.75rem;
    border-radius: .25rem;
}

.btn-rolo-round{
    padding: 2px 8px;
    border-radius: 2px;
    font-size: 0.75rem;
    border-radius: 40px;
}

aw-wizard-step > form > h4 {
    text-transform: uppercase;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;
}

