.header-company-logo{
    height: 30px;
}

.client-menu-icon {
    width: 20px !important;
    margin-right: 5px;
}

.rolo-prepend {
    background-color:  #d7d7d7 !important;
}

.add-carousel-item {
    border: 1px solid #d7d7d7;
    border-radius: 0.75rem !important;    
    text-align: center;
    font-size: large !important;
    color: #626262 !important;

    i{
        font-size: large !important;
        color: #626262 !important;
    }
}

.rolo-card-dash {
    height: 200px;    
    transition: 0.5s;
    background-size: 200% auto;
    cursor: pointer;
    
    >div {
        padding: 5px !important;
        border-radius: 15px;
    }
    .rolo-card-blue {
        background-image: linear-gradient(to right top, #bce1eb, #bce1eb , #bce1eb ) !important;
        color: #231139;
    }

    .rolo-card-purple {
        background-image: linear-gradient(to right top, #231139, #231139 , #231139 ) !important;
        color: #fff;
    }

    i {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
}

tabset > ul {
    margin-bottom: 5px !important;
}