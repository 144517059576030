html {
  scroll-behavior: smooth;
}

.rolo-custom-icon {
    display: inline-block;
    width: 150px !important;
    height: 40px !important;    
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }

 

  .c-pointer {
    cursor: pointer;
  }

  .goto_chevron {
    font-size: 25px;
    color: #000;
    font-weight: bold;
  }

  .fs-25{
    font-size: 25px;
  }

  .fs-15{
    font-size: 15px;
  }

  .card-service {
    border-radius: 0rem !important;
    box-shadow: unset;
  }

  .radious-1 {
    border-radius: 0.40rem;
  }

  .card-option-body {
    border-radius: 0.25rem;
  }

  .card-option-footer {
    border-top: unset;
    background-color: unset;
  }

  .card-option {
    background-color: transparent;
    box-shadow: unset;
  }

  .card-option-tagline,
  .card-option-title{
    height: auto !important;
    text-transform: uppercase !important;
  }

  .rolo-footer {
    @include respond-above(sm){
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1030;
    }
  }

  .feature-text-container{
    h2{
      text-align: center;
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
      margin: 0;
    }
    p{
      text-align: justify;
    }
  }

  .btn-lng {
    opacity: 0.75;
  }
  .btn-lng.active {
    opacity: 1;
  }

  .btn-goto-top {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */    
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 3px 7px; /* Some padding */
    border-radius: 50%; /* Rounded corners */
    font-size: 15px; /* Increase font size */
  }
  
  .btn-goto-top:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }

  .selected-page{
    background-color: #d7d7d7;
  }

  .page-pick-color{
    width: 20px; 
    height:20px;
    border: 1px solid #d7d7d7;
    cursor: pointer;
    margin: 5px;
  }


  .embed-responsive-21by9::before {
    padding-top: 145.857% !important;
  }

  .item-carousel-thumbnail-preview,
  .item-post-thumbnail-preview,
  .item-service-thumbnail-preview,
  .item-carousel-thumbnail{
    width: 100px;
    min-height: 80px;
    overflow: hidden;
    border: 1px solid #cdcdcd;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-top: 1px;
    border-radius: 0px !important;
  }

  .item-carousel-thumbnail-preview {
    width: 200px !important;
    min-height: 112.25px !important;
    margin-left: 25%;
  }

  .select-icon-modal {
     .simple-line-icons .glyph,
     .mind-icons .glyph {
        height: auto !important;
    }

    .simple-line-icons .glyph:hover,
    .mind-icons .glyph:hover {
        background-color: #cdcdcd;
        /*border: 1px solid #cdcdcd;*/
        color: #fff;
    }

    .scroll {
      height: 350px;
    }

  }

  .item-post-thumbnail {
    height: 50px !important; 
    width: 75px !important;
  }

  .item-post-thumbnail-preview {
    /* min-height: 133.33px !important;  */
    height: 165px !important;
    width: 220px !important;
    margin-left: 25%;
  }

  .item-service-thumbnail{
    height: 50px !important; 
    width: 50px !important;
  }

  .item-service-thumbnail-preview {
    height: 100px !important;
    width: 100px !important;
    margin-left: 25%;
  }

  .icon-page-home {
    color: #000 ;
    font-weight: bold;
  }

  .icon-home {
    color: #555;
    font-weight: normal;
  }