.img-inactive{
    filter: grayscale(100%);
}

.group-accordion .panel-heading {
    padding: 0.5rem !important;
}

.group-accordion .panel-body {
    padding: 0.5rem !important;
    background-color: #f8f8f8;
}

.rl-cursor-pointer{
    cursor: pointer;
}

.table-font-sm{
    font-size: smaller;
}

.table-font-sm tbody tr th,
.table-font-sm tbody tr td,
.table-font-sm thead tr th,
.table-font-sm thead tr td{
    padding:  .25rem !important;
}

.table-font-sm .badge {
    font-size: 90%;
}

.menu-text-description {
    font-size: smaller !important;
    line-height: 12px;
    margin-bottom: 0px;
}


.table-smaller tr td,
.table-smaller tr th {
  font-size: smaller;
  padding: .05rem !important;  
  padding-left: 1rem !important;
  border: 0px;
}

.table-small tr td,
.table-small tr th {
  font-size: small;
  padding: .05rem !important;  
  padding-left: 1rem !important;
  border: 0px;
}


.category-thumbnail {
    width: 100px;
    min-height: 70px;
    overflow: hidden;    
    /* border: 1px solid; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /*margin-top: 1px;*/
}

.subcategory-thumbnail {
    width: 30px;
    min-height: 30px;
    overflow: hidden;    
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 1px;
}

.order-icon {
    font-size: 15px;
}

.table-light >tbody  tr th,
.table-light >tbody tr td,
.table-light> thead tr th,
.table-light> thead tr td{
    padding:  .5rem !important;
}

.tr-active{
    background-color: #faebd7;
}

.table-order-items th,
.table-order-items td {
    padding: 5px 10px 5px 0px !important;
    //color: darkgreen;
}




@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  .animate-flicker {
     -webkit-animation: flickerAnimation .5s infinite;
     -moz-animation: flickerAnimation .5s infinite;
     -o-animation: flickerAnimation .5s infinite;
      animation: flickerAnimation .5s infinite;
  }
  
  .lbl-remove {
      display: none;
      position: absolute;
      /*opacity: 0.5;*/
/*background-color: #145388;
padding: 2px;
color: #fff;
font-size: smaller;*/
  }

  .lbl-remove:hover {
    display: block;
  }

  .img-display:hover + .lbl-remove{
      display: block;
  }

  .bs-datepicker-head button {
      color: #000000 !important;
  }